import React from 'react';
import styled from 'styled-components';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

const Section = styled.section`
  & button {
    width: 46px;
    height: 46px;
    margin: 0 1rem;
  }

  & svg {
    border-radius: 5px;
  }
`;

const SocialShareLinks = ({ shareUrl, title, description, isColumn }) => (
  <Section>
    <TwitterShareButton url={shareUrl} title={title}>
      <TwitterIcon
        size={46}
        iconFillColor={isColumn ? `#4c8ff9` : '#ffffff'}
        bgStyle={isColumn ? { fill: 'transparent' } : {}}
      />
    </TwitterShareButton>
    <FacebookShareButton url={shareUrl} quote={title}>
      <FacebookIcon
        size={46}
        iconFillColor={isColumn ? `#4c8ff9` : '#ffffff'}
        bgStyle={isColumn ? { fill: 'transparent' } : {}}
      />
    </FacebookShareButton>
    <LinkedinShareButton url={shareUrl}>
      <LinkedinIcon
        size={46}
        iconFillColor={isColumn ? `#4c8ff9` : '#ffffff'}
        bgStyle={isColumn ? { fill: 'transparent' } : {}}
      />
    </LinkedinShareButton>
    <EmailShareButton url={shareUrl} subject={title} body={description}>
      <EmailIcon
        size={46}
        iconFillColor={isColumn ? `#4c8ff9` : '#ffffff'}
        bgStyle={isColumn ? { fill: 'transparent' } : {}}
      />
    </EmailShareButton>
  </Section>
);

export default SocialShareLinks;
